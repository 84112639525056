exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-downloads-tsx": () => import("./../../../src/pages/downloads.tsx" /* webpackChunkName: "component---src-pages-downloads-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-references-tsx": () => import("./../../../src/pages/references.tsx" /* webpackChunkName: "component---src-pages-references-tsx" */),
  "component---src-pages-tools-curvature-calculator-tsx": () => import("./../../../src/pages/tools/curvature-calculator.tsx" /* webpackChunkName: "component---src-pages-tools-curvature-calculator-tsx" */),
  "component---src-pages-tools-index-tsx": () => import("./../../../src/pages/tools/index.tsx" /* webpackChunkName: "component---src-pages-tools-index-tsx" */),
  "component---src-pages-tools-sottocoppo-requirements-tsx": () => import("./../../../src/pages/tools/sottocoppo-requirements.tsx" /* webpackChunkName: "component---src-pages-tools-sottocoppo-requirements-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-isocoppo-tool-tsx": () => import("./../../../src/templates/isocoppo-tool.tsx" /* webpackChunkName: "component---src-templates-isocoppo-tool-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-reference-tsx": () => import("./../../../src/templates/reference.tsx" /* webpackChunkName: "component---src-templates-reference-tsx" */)
}


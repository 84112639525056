/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import type { GatsbyBrowser } from 'gatsby';

import './src/scss/app.scss';

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  prevRouterProps,
  routerProps,
}) => {
  return prevRouterProps?.location.pathname !== routerProps.location.pathname;
};
